
export default {
  name: 'HeroComponent',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      animatedPath: null
    };
  }
  // mounted() {
  //   // THIS need to be rewritten for NUXT

  //   // Get the id of the <path> element and the length of <path>
  //   this.animatedPath = document.getElementById('animatedPath');
  //   const length = this.animatedPath.getTotalLength();

  //   // The start position of the drawing
  //   this.animatedPath.style.strokeDasharray = length;

  //   this.animatedPath.style.strokeDashoffset = length;

  //   window.addEventListener('scroll', draw);

  //   function draw() {
  //     const scrollpercent =
  //       (document.body.scrollTop + document.documentElement.scrollTop) /
  //       (document.documentElement.scrollHeight - document.documentElement.clientHeight);

  //     const draw = length * scrollpercent * 8;

  //     // Reverse the drawing (when scrolling upwards)
  //     this.animatedPath.style.strokeDashoffset = length - draw;
  //   }
  // }
};
